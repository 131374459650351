<template>
    <modal ref="modalSolicitarActualizar" titulo="Solicitar actualización de datos" no-aceptar adicional="Solicitar" @adicional="crearSolicitudes()">
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <p v-if="this.$route.name=='admin.datos-cuentas.lanzamientos.clientes' " class="text-general f-15 mb-3">¿Desea enviar la solicitud de actualización de datos a los clientes?</p>
                <p v-if="this.$route.name=='admin.datos-cuentas.lanzamientos.leecheros'" class="text-general f-15 mb-3">¿Desea enviar la solicitud de actualización de datos a los Vendedores?</p>
                <p class="text-general f-15 mb-5">Se le solicitarán los datos requeridos al usuario en el momento de ejecutar la aplicación</p>
                <div v-for="d in datos" :key="d.id" class="row mx-0 mb-3">
                    <el-checkbox v-model="d.checked" class="check-dark" />
                    <div class="col text-general f-15 px-2">
                        {{ d.nombre }}
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import datosCuentas from '~/services/DatosCuentas/datosCuentas'
export default {
    data(){
        return{
            datos: [],
            form: [],
            checked: [],
        }
    },

    methods: {
        async toggle(form){
            await this.listarCedisNavBar();
            this.$refs.modalSolicitarActualizar.toggle();
            this.form = form
        },
        async listarCedisNavBar(){
            const {data} = await datosCuentas.listarCedis();
            this.datos = data.map(el=> {
                el.checked = false
                return el
            })
        },
        async crearSolicitudes(){
            const origen = this.$route.name
            const cliente = 'admin.datos-cuentas.lanzamientos.clientes'
            const leechero = 'admin.datos-cuentas.lanzamientos.leecheros'
            let destino
            switch (origen){
            case cliente:
                destino = 1
                break;
            case leechero:
                destino = 2
                break;
            default:
                break;
            }
            let cedis = this.datos.filter(dato=>dato.checked==true)
            let form = {
                nacimiento: this.form.nacimiento? 1:0,
                genero: this.form.genero? 1:0,
                direccion: this.form.direccion? 1:0,
                password: this.form.password? 1:0,
                facturacion: this.form.facturacion? 1:0,
                fecha_inicio: this.form.fecha[0],
                fecha_fin: this.form.fecha[1],
                cedis: cedis,
                destino: destino
            }
            if (cedis != ""){
                try {
                    const {data} = await datosCuentas.crearSolicitudes(form)
                    this.notificacion("Éxito","Solicitud creada correctamente","success")
                    this.$emit('update')
                    this.$refs.modalSolicitarActualizar.toggle();
                } catch (error){
                    return this.error_catch(error);
                }
            } else {
                return this.notificacion("Error","Seleccione al menos un cedis","warning")
            }
        }
    },

}
</script>
